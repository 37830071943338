import { AddressZero, Zero } from "@ethersproject/constants";
import CPK, {
  CpkTransactionManager,
  EthersAdapter,
  OperationType,
  defaultNetworks,
} from "@polymarket/proxy-safe-sdk";
import { CallType } from "@polymarket/sdk/lib/types";
import { ethers } from "ethers";

export const getContractProxyKit = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  externalProvider
) => {
  const wallet = externalProvider.getSigner();
  const ethLibAdapter = new EthersAdapter({ ethers, signer: wallet });

  const transactionManager = new CpkTransactionManager();

  return CPK.create({
    ethLibAdapter,
    transactionManager,
    networks: {
      // Duplicate ETH network config to Base
      8453: defaultNetworks[1],
    },
  });
};

export const getCpkOperation = (polyType) => {
  switch (polyType) {
    case CallType.Call:
      return OperationType.Call;
    case CallType.DelegateCall:
      return OperationType.DelegateCall;
    default:
      throw new Error(
        `Unsupported CallType ${polyType} passed into 'getCPKOperation'`
      );
  }
};

// if we change the master copy this will need to change too
const initCodeHash =
  "0x2bce2127ff07fb632d16c8347c4ebf501f4841168bed00d9e6ef715ddb6fcecf";

export const getCpkProxyAddress = (address) => {
  const salt = ethers.utils.keccak256(
    ethers.utils.defaultAbiCoder.encode(["address"], [address])
  );

  const proxyAddress = ethers.utils.getCreate2Address(
    "0xaacFeEa03eb1561C4e67d661e40682Bd20E3541b",
    salt,
    initCodeHash
  );

  return proxyAddress;
};

export const deploySafe = async (cpk, provider) => {
  try {
    const transactionManager = new CpkTransactionManager();

    if (cpk.contractManager) {
      const params = {
        payment: Zero,
        paymentReceiver: AddressZero,
        paymentToken: AddressZero,
        contractManager: cpk.contractManager,
        provider,
      };
      const response = await transactionManager.deploySafe(params);
      const result = await provider.waitForTransaction(response);
      return result;
    }
  } catch (err) {
    throw new Error(`error deploying safe, ${err.message}`);
  }
};

import { useState, useEffect } from "react";
import { CHAIN_NAME, ENVIRONMENT } from "../helpers/constants";
import MATIC_TOKEN_LIST from "../helpers/maticTokenList";

function getTokenList({ url, firstTokenAddress, mapper }) {
  return fetch(url)
    .then((res) => res.json())
    .then((data) => {
      const tokens = mapper(data);

      const tokenIndex = tokens.findIndex(
        (token) => token.address === firstTokenAddress
      );

      if (tokenIndex > -1) {
        const [tokenToMove] = tokens.splice(tokenIndex, 1);
        tokens.unshift(tokenToMove);
      }

      return tokens;
    });
}

export default function useTokenList() {
  const [tokens, setTokens] = useState([]);

  useEffect(() => {
    let tokenListP = null;

    if (CHAIN_NAME === "mainnet" && ENVIRONMENT === "mainnet") {
      tokenListP = getTokenList({
        url: "https://tokens.coingecko.com/uniswap/all.json",
        firstTokenAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48", // USDC
        mapper: (res) => res.tokens,
      });
    } else if (CHAIN_NAME === "mainnet" && ENVIRONMENT === "mumbai") {
      tokenListP = Promise.resolve([
        {
          name: "USDC",
          address: "0x9DA9Bc12b19b22d7C55798F722A1B6747AE9A710",
          decimals: 6,
        },
      ]);
    } else if (CHAIN_NAME === "matic" && ENVIRONMENT === "mainnet") {
      tokenListP = Promise.resolve(MATIC_TOKEN_LIST);
    } else if (CHAIN_NAME === "base" && ENVIRONMENT === "mainnet") {
      tokenListP = getTokenList({
        url: "https://static.optimism.io/optimism.tokenlist.json",
        firstTokenAddress: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
        mapper: (res) =>
          res.tokens.filter(
            (token) =>
              // Remove non base tokens
              token.chainId === 8453
          ),
      });
    } else {
      throw new Error("Unsupported chain/environment combination");
    }

    tokenListP.then((tokens) => {
      setTokens(tokens);
    });
  }, []);

  return tokens;
}

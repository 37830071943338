import { useCallback, useState, useEffect, useMemo } from "react";
import { useUserInfo } from "../context/UserContext";
import CopyAddress from "./CopyAddress";
import useTokenList from "../hooks/useTokenList";
import {
  getERC20Balance,
  sendERC20Safe,
  sendETHSafe,
} from "../helpers/contracts";
import { CHAIN_CURRENCY } from "../helpers/constants";
import { isAddress } from "@ethersproject/address";
import { Zero } from "@ethersproject/constants";
import { formatUnits, formatEther } from "@ethersproject/units";
import Logout from "./Logout";

import LoadingModal from "./LoadingModal";

import { useAddress } from "../context/MetamaskContext";
import {
  deploySafe,
  getContractProxyKit,
  getCpkProxyAddress,
} from "../helpers/contractProxyKit";
import DeployingModal from "./DeployingModal";
import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "@ethersproject/bignumber";

export default function MMSendModal() {
  const [selectedToken, setSelectedToken] = useState(CHAIN_CURRENCY);
  const [quantity, setQuantity] = useState("");

  const [destinationAddress, setDestinationAddress] = useState(null);
  const [sending, setSending] = useState(false);
  const [deploying, setDeploying] = useState(false);
  const [proxyDeployed, setProxyDeployed] = useState(false);
  const [proxyAddress, setProxyAddress] = useState("");
  const [filterText, setFilterText] = useState("");
  const tokens = useTokenList();
  const user = useUserInfo();
  const mmAddress = useAddress();
  const { provider } = useWeb3React();
  const [signerBalance, setSignerBalance] = useState(BigNumber.from("0"));
  const [gasPrice, setGasPrice] = useState(BigNumber.from("0"));

  useEffect(() => {
    let code;
    const isSafeDeployed = async () => {
      if (user && mmAddress) {
        const proxyAdd = getCpkProxyAddress(mmAddress);
        setProxyAddress(proxyAdd);
        code = await provider.getCode(proxyAdd);
        if (code !== "0x") {
          setProxyDeployed(true);
        } else {
          setProxyDeployed(false);
        }
        console.log({ proxyAddress, isDeployed: code !== "0x" });
      }
    };
    console.log(code);

    isSafeDeployed();
  }, [provider, mmAddress, proxyAddress, user]);

  useEffect(() => {
    setFilterText("");
  }, [tokens]);

  const options = useMemo(() => {
    if (!filterText) {
      return tokens;
    }
    return tokens.filter(
      (token) =>
        (token.symbol &&
          token.symbol
            .toLowerCase()
            .includes(String(filterText).toLowerCase())) ||
        (token.address &&
          token.address
            .toLowerCase()
            .includes(String(filterText).toLowerCase()))
    );
  }, [filterText, tokens]);

  const fetchSelectedTokenQuantity = useCallback(async () => {
    if (selectedToken && proxyAddress) {
      let qty = Zero;

      if (selectedToken.symbol === CHAIN_CURRENCY.symbol) {
        qty = await provider.getBalance(proxyAddress);
      } else {
        const tokenAddress = selectedToken.address;
        const decimals = selectedToken.decimals;

        //Fetch the qty here
        qty = await getERC20Balance(
          tokenAddress,
          provider,
          proxyAddress,
          decimals
        );
      }

      setQuantity(qty);
    } else {
      setQuantity("");
    }
  }, [provider, proxyAddress, selectedToken]);

  const fetchSignerBalance = useCallback(async () => {
    if (!provider || !mmAddress) {
      return;
    }
    const qty = await provider.getBalance(mmAddress);
    setSignerBalance(qty);
  }, [provider, mmAddress]);

  const fetchGasData = useCallback(async () => {
    if (!provider) {
      return;
    }
    const data = await provider.getFeeData();
    const swapUnits = "50000"; // cost to swap
    const safeUnits = "200000"; // cost to deploy safe
    const swapGasPrice = data.maxFeePerGas
      .add(data.maxPriorityFeePerGas)
      .mul(BigNumber.from(swapUnits)); // with tip
    const safeGasPrice = data.maxFeePerGas
      .add(data.maxPriorityFeePerGas)
      .mul(BigNumber.from(safeUnits)); // with tip
    const totalGasPrice = swapGasPrice.add(safeGasPrice);
    setGasPrice(totalGasPrice);
  }, [provider]);

  useEffect(() => {
    fetchSelectedTokenQuantity();
    fetchSignerBalance();
    fetchGasData();
  }, [fetchSelectedTokenQuantity, fetchSignerBalance, fetchGasData]);

  const sendTokens = async (e) => {
    e.preventDefault();
    if (user.isMetamask) {
      try {
        if (
          isAddress(destinationAddress) &&
          destinationAddress.toLowerCase() !== proxyAddress.toLowerCase() &&
          parseFloat(quantity) > 0
        ) {
          setSending(true);
          const tokenAddress = selectedToken.address;

          if (selectedToken.symbol === CHAIN_CURRENCY.symbol) {
            const response = await sendETHSafe(
              provider,
              destinationAddress,
              quantity
            );
            const result = await response.transactionResponse.wait();

            setSending(false);
            fetchSelectedTokenQuantity();
            return result;
          } else {
            const response = await sendERC20Safe(
              tokenAddress,
              provider,
              destinationAddress,
              quantity
            );
            console.log("res", response);
            const result = await response.transactionResponse.wait();

            setSending(false);
            fetchSelectedTokenQuantity();
            fetchSignerBalance();
            fetchGasData();
            return result;
          }
        } else {
          alert("Address is not valid");
        }
      } catch (err) {
        setSending(false);
        console.log("Error Sending Tokens", err);
      }
    }
  };
  const deployProxySafe = async (e) => {
    e.preventDefault();
    let result;
    if (user.isMetamask) {
      try {
        setDeploying(true);
        const cpk = await getContractProxyKit(provider);
        console.log(cpk);
        result = await deploySafe(cpk, provider);
      } catch (err) {
        setDeploying(false);
        console.log("Error Deploying safe", err);
      }
    }
    if (result) {
      setDeploying(false);
      setProxyDeployed(true);
      return result;
    }
  };

  if (sending) {
    return <LoadingModal />;
  }
  if (deploying) {
    return <DeployingModal />;
  }

  const notEnoughForGas = signerBalance.lt(gasPrice);
  const formattedGasPrice = formatEther(gasPrice);
  const formattedSignerBalance = formatEther(signerBalance);

  return (
    <>
      <div className="SendModal">
        <p>
          Your Polymarket Address is{" "}
          {typeof proxyAddress === "string" && proxyAddress.length > 8
            ? `${proxyAddress.slice(0, 8)}...`
            : proxyAddress}
        </p>

        <section
          style={{
            border: "1px solid red",
            padding: "16px",
            borderRadius: "8px",
          }}
        >
          <h3 style={{ color: "red", marginTop: 0 }}>
            Note: You will need {CHAIN_CURRENCY.symbol} to pay for gas fees
            associated with the transaction!
          </h3>
          <h4 style={{ marginBottom: "4px" }}>Your owner address:</h4>
          <CopyAddress proxyWalletAddress={mmAddress} />

          <h4 style={{ marginBottom: "4px" }}>has:</h4>
          <p style={{ color: notEnoughForGas ? "red" : "green", margin: 0 }}>
            {typeof formattedSignerBalance === "string" &&
            formattedSignerBalance.length > 6
              ? formattedSignerBalance.slice(0, 8)
              : formattedSignerBalance}
            <span>{CHAIN_CURRENCY.symbol}</span>
          </p>
          <button
            className="LogoutButton reload"
            onClick={fetchSignerBalance}
            style={{ marginTop: "4px" }}
          >
            Refresh Balance
          </button>

          <h4 style={{ marginBottom: "4px" }}>You will need at least:</h4>
          <p style={{ margin: 0 }}>
            {typeof formattedGasPrice === "string" &&
            formattedGasPrice.length > 6
              ? formattedGasPrice.slice(0, 8)
              : formattedGasPrice}{" "}
            {CHAIN_CURRENCY.symbol} <span>in your owner address</span>
          </p>

          <button
            className="LogoutButton reload"
            onClick={fetchGasData}
            style={{ marginTop: "4px" }}
          >
            Refresh Gas Price
          </button>
        </section>

        {selectedToken && proxyAddress && (
          <div>
            <div className="SendModal__Token">
              <img src={selectedToken.logoURI} alt={selectedToken.symbol} />
            </div>
            <div>
              You own{" "}
              {quantity
                ? formatUnits(quantity, selectedToken.decimals)
                : "0.00"}{" "}
              {selectedToken.symbol}{" "}
              {selectedToken.address ? `(${selectedToken.address})` : ""}
            </div>
            <button
              className="LogoutButton reload"
              onClick={fetchSelectedTokenQuantity}
            >
              Refresh Balance
            </button>
          </div>
        )}

        {selectedToken &&
          proxyDeployed &&
          proxyAddress &&
          quantity &&
          quantity.gt(0) && (
            <form onSubmit={sendTokens}>
              <div>
                <div className="Form__Col">
                  <input
                    placeholder="Paste Recovery Address"
                    onChange={(e) => setDestinationAddress(e.target.value)}
                    value={destinationAddress}
                  />
                </div>
              </div>
              <div>
                <button className="Button__Blue" disabled={notEnoughForGas}>
                  {sending ? <>Sending</> : <>Send</>}
                </button>
                {notEnoughForGas ? (
                  <span style={{ color: "red" }}>
                    You do not have enough {CHAIN_CURRENCY.symbol} in your owner
                    address
                  </span>
                ) : null}
              </div>
            </form>
          )}

        {selectedToken &&
          !proxyDeployed &&
          proxyAddress &&
          quantity &&
          quantity.gt(0) && (
            <form onSubmit={deployProxySafe}>
              <div>
                <button className="Button__Blue" disabled={notEnoughForGas}>
                  {sending ? <>Deploying</> : <>Deploy Safe</>}
                </button>
                {notEnoughForGas ? (
                  <span style={{ color: "red" }}>
                    You do not have enough {CHAIN_CURRENCY.symbol} in your owner
                    address
                  </span>
                ) : null}
              </div>
            </form>
          )}

        <div>
          <label>Select Asset</label>
          <div className="Form__Col" style={{ marginBottom: "16px" }}>
            <input
              placeholder="Find Token"
              onChange={(e) => {
                setFilterText(e.target.value);
              }}
              value={filterText}
            />
          </div>
          <div className="Options__Container">
            <div
              className={`Option ${
                selectedToken.symbol === CHAIN_CURRENCY.symbol ? "active" : ""
              }`}
              onClick={() => setSelectedToken(CHAIN_CURRENCY)}
              key="0x0"
            >
              <img src={CHAIN_CURRENCY.logoURI} alt={CHAIN_CURRENCY.symbol} />
              <h3>{CHAIN_CURRENCY.symbol}</h3>
            </div>
            {options.slice(0, 500).map((token) => (
              <div
                className={`Option ${
                  selectedToken.symbol === token.symbol ? "active" : ""
                }`}
                key={token.address}
                onClick={() => {
                  console.log({ token });
                  setSelectedToken(token);
                }}
              >
                <img src={token.logoURI} alt={token.symbol} />
                <h3>{token.symbol}</h3>
              </div>
            ))}
          </div>
        </div>
      </div>

      {!sending && !deploying && <Logout />}
    </>
  );
}

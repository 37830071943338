/* eslint-disable import/no-anonymous-default-export */
import { useState } from "react";
import { useLoadUser, useLoadMMUser } from "../context/UserContext";
import { useMainnetMagic } from "../context/MagicLinkContext";
import { useMMLogin } from "../context/MetamaskContext";
import { metaMask } from "../utils/web3-react/metaMask";
import { coinbaseWallet } from "../utils/web3-react/coinbaseWallet";
import { walletConnectV2 } from "../utils/web3-react/walletConnectV2";
import { MAGIC_ENABLED } from "../helpers/constants";

export default () => {
  const [email, setEmail] = useState("");
  const [magicLoading, setMagicLoading] = useState(false);
  const [magicOauthLoading, setMagicOauthLoading] = useState(false);
  const [metaMaskLoading, setMetaMaskLoading] = useState(false);
  const [coinbaseLoading, setCoinbaseLoading] = useState(false);
  const [walletConnectLoading, setWalletConnectLoading] = useState(false);
  const loadUser = useLoadUser();
  const magic = useMainnetMagic();
  const loginWithMetamask = useMMLogin();
  const loadMMUser = useLoadMMUser();

  const handleSubmit = async (e) => {
    setMagicLoading(true);
    e.preventDefault();

    try {
      await magic.auth.loginWithMagicLink({
        email,
        showUI: true,
      });

      await loadUser(magic.rpcProvider, "magic");
    } catch (err) {
      setMagicLoading(false);
    }
  };

  const handleOauthClick = async (e) => {
    setMagicOauthLoading(true);
    e.preventDefault();

    try {
      localStorage.setItem("polymarket.recovery.magicOauthProvider", "google");
      await magic.oauth.loginWithRedirect({
        provider: "google",
        redirectURI: window.location.origin,
      });
    } catch (err) {
      setMagicOauthLoading(false);
    }
  };

  const handleMMClick = async (connector, connectorName) => {
    if (connectorName === "metaMask") {
      setMetaMaskLoading(true);
    } else if (connectorName === "coinbaseWallet") {
      setCoinbaseLoading(true);
    } else {
      setWalletConnectLoading(true);
    }

    try {
      await loginWithMetamask(connector, connectorName);

      await loadMMUser(false);
    } catch (err) {
      console.log(err);

      if (connectorName === "metaMask") {
        setMetaMaskLoading(false);
      } else if (connectorName === "coinbaseWallet") {
        setCoinbaseLoading(false);
      } else {
        setWalletConnectLoading(false);
      }
    }
  };

  return (
    <div className="LoginSignupModal">
      <div className="LoginSignupModal__Header">
        <h2 className="LoginSignupModal__HeaderText">Login</h2>
      </div>
      <div className="LoginSignupModal__Content">
        {MAGIC_ENABLED && (
          <>
            <form
              onSubmit={handleSubmit}
              className="LoginSignupModal__Form"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "16px",
                marginTop: "16px",
                marginBottom: "32px",
              }}
            >
              <div className="Form__Col">
                <input
                  placeholder="Your email address"
                  autoComplete="email"
                  value={email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <button
                className="Button__Blue Button__Full"
                style={{
                  marginTop: 0,
                }}
              >
                {magicLoading ? <>Loading</> : <>Login</>}
              </button>
            </form>

            <hr />
          </>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            marginTop: "32px",
            marginBottom: "16px",
          }}
        >
          {MAGIC_ENABLED && (
            <button
              className="Button__Blue Button__Full"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                height: "58px",
                marginTop: 0,
              }}
              onClick={handleOauthClick}
            >
              {magicOauthLoading ? (
                <>Loading</>
              ) : (
                <>
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 45 45"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M44.5624 18.0934H42.75V18H22.5V27H35.2159C33.3607 32.2391 28.3759 36 22.5 36C15.0446 36 9 29.9554 9 22.5C9 15.0446 15.0446 9 22.5 9C25.9414 9 29.0722 10.2982 31.4561 12.4189L37.8202 6.05475C33.8017 2.30962 28.4265 0 22.5 0C10.0744 0 0 10.0744 0 22.5C0 34.9256 10.0744 45 22.5 45C34.9256 45 45 34.9256 45 22.5C45 20.9914 44.8447 19.5187 44.5624 18.0934Z"
                      fill="#FBC02D"
                    />
                    <path
                      d="M2.59421 12.0274L9.98658 17.4487C11.9868 12.4965 16.8311 9 22.5 9C25.9413 9 29.0722 10.2982 31.4561 12.4189L37.8202 6.05475C33.8017 2.30962 28.4265 0 22.5 0C13.8577 0 6.36296 4.87912 2.59421 12.0274Z"
                      fill="#E53935"
                    />
                    <path
                      d="M22.4998 45C28.3116 45 33.5923 42.7759 37.585 39.159L30.6212 33.2663C28.3622 34.9774 25.5542 36 22.4998 36C16.6476 36 11.6785 32.2684 9.80646 27.0608L2.46921 32.7139C6.19296 40.0005 13.7552 45 22.4998 45Z"
                      fill="#4CAF50"
                    />
                    <path
                      d="M44.5621 18.0934L44.5441 18H42.7498H22.4998V27H35.2156C34.3246 29.5166 32.7058 31.6867 30.6178 33.2674C30.6189 33.2662 30.62 33.2662 30.6211 33.2651L37.5849 39.1579C37.0921 39.6056 44.9998 33.75 44.9998 22.5C44.9998 20.9914 44.8445 19.5187 44.5621 18.0934Z"
                      fill="#1565C0"
                    />
                  </svg>
                  Login with Google
                </>
              )}
            </button>
          )}

          <button
            className="Button__Blue Button__Full"
            onClick={() => handleMMClick(metaMask, "metaMask")}
            style={{
              marginTop: 0,
            }}
          >
            {metaMaskLoading ? <>Loading</> : <>Login with Metamask</>}
          </button>

          <button
            className="Button__Blue Button__Full"
            onClick={() => handleMMClick(coinbaseWallet, "coinbaseWallet")}
            style={{
              marginTop: 0,
            }}
          >
            {coinbaseLoading ? <>Loading</> : <>Login with Coinbase Wallet</>}
          </button>

          <button
            className="Button__Blue Button__Full"
            onClick={() => handleMMClick(walletConnectV2, "walletConnect")}
            style={{
              marginTop: 0,
            }}
          >
            {walletConnectLoading ? (
              <>Loading</>
            ) : (
              <>Login with WalletConnect</>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
